import React from 'react';
import { Link } from 'gatsby';

import '../../styles/layout/components/mobileMenu.scss';

import ProductMobileMainMenu from '../../components/ProductMobileMainMenu';

import AnimatedElements from '../../components/AnimatedElements';

import useLanguage from '../../hooks/useLanguage';
import enMessages from '../../i18n/navbar/en.json';
import ruMessages from '../../i18n/navbar/ru.json';

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const currentYear = new Date().getFullYear();

const MobileNavbar = ({ locale }) => {
  const [currentLanguage] = useLanguage();

  const prefixUrl = React.useMemo(
    () => ((currentLanguage || 'ru') === 'en' ? '/en' : ''),
    [currentLanguage],
  );

  return (
    <div className="MobileMenu">
      <AnimatedElements theme="1" />
      <div className="MobileMenu-Inner">
        <div className="container">
          <div className="MobileMenu-Row">
            <div className="MobileMenu-Cell MobileMenu-Cell_half_links">
              <h5 className="MobileMenu-CellTitle">
                {messages[locale].businessSolutions}
              </h5>
              <ProductMobileMainMenu menu={messages[locale].mainMenuItems} />
            </div>
          </div>
          <div className="MobileMenu-Row">
            <div className="MobileMenu-Cell MobileMenu-Cell_half">
              <div className="MobileMenu-CellTitle">
                {messages[locale].company}
              </div>
              <div className="listMobileMenuLinks">
                <Link
                  to={`${prefixUrl}/about`}
                  className="mobileMenuLink"
                  activeClassName="active"
                >
                  {messages[locale].aboutBank}
                </Link>
                <Link
                  to={`${prefixUrl}/legal`}
                  className="mobileMenuLink"
                  activeClassName="active"
                >
                  {messages[locale].legalInformation}
                </Link>
                <Link
                  to={`${prefixUrl}/pricing`}
                  className="mobileMenuLink"
                  activeClassName="active"
                >
                  {messages[locale].pricing}
                </Link>
                <Link
                  to={`${prefixUrl}/faq`}
                  className="mobileMenuLink"
                  activeClassName="active"
                >
                  FAQ
                </Link>
                <Link
                  to={`${prefixUrl}/careers`}
                  className="mobileMenuLink"
                  activeClassName="active"
                >
                  {messages[locale].vacancy}
                </Link>
                <Link
                  to={`${prefixUrl}/blog`}
                  className="mobileMenuLink"
                  activeClassName="active"
                >
                  {messages[locale].blog}
                </Link>
                {locale === 'ru' && (
                  <Link
                    to={`${prefixUrl}/contacts`}
                    className="mobileMenuLink"
                    activeClassName="active"
                  >
                    {messages[locale].feedback}
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="MobileMenu-Description">
            {messages[locale].copyright.replace(
              /\{\{\s*currentYear\s*\}\}/i,
              currentYear,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
