import React from 'react';
import PropTypes from 'prop-types';

import "../styles/components/animatedElement.scss";
import "../styles/pulse/pulse.scss";
import bubbleRed from "../images/icons/bubble-svg/Bubble-Red.svg";
import bubbleBlue from "../images/icons/bubble-svg/Bubble-Blue.svg";
import bubbleGreen from "../images/icons/bubble-svg/Bubble-Green.svg";
import bubbleYellow from "../images/icons/bubble-svg/Bubble-Yellow.svg";

const ANIMATED_ELEMENT_TYPES = {
    1: {
        elements: [
            {
                path: bubbleRed,
                size: '1',
                animate: '1'
            },
            {
                path: bubbleBlue,
                size: '2',
                animate: '2'
            },
            {
                path: bubbleGreen,
                size: '3',
                animate: '3'
            },
            {
                path: bubbleYellow,
                size: '4',
                animate: '4'
            }
        ]
    },
    2: {
        elements: [
            {
                path: bubbleYellow,
                size: '4',
                animate: '4'
            },
            {
                path: bubbleBlue,
                size: '4',
                animate: '5'
            },
            {
                path: bubbleRed,
                size: '5',
                animate: '5'
            },
            {
                path: bubbleGreen,
                size: '6',
                animate: '6'
            }
        ]
    },
    3: {
        elements: [
            {
                path: bubbleYellow,
                size: '4',
                animate: '1'
            },
            {
                path: bubbleBlue,
                size: '7',
                animate: '4'
            },
            {
                path: bubbleRed,
                size: '8',
                animate: '6'
            },
            {
                path: bubbleGreen,
                size: '9',
                animate: '4'
            }
        ]
    },
    4: {
        elements: [
            {
                path: bubbleGreen,
                size: '10',
                animate: '4'
            },
            {
                path: bubbleYellow,
                size: '11',
                animate: '4'
            },
            {
                path: bubbleRed,
                size: '12',
                animate: '1'
            },
            {
                path: bubbleBlue,
                size: '13',
                animate: '1'
            }
        ]
    },
    5: {
        elements: [
            {
                path: bubbleRed,
                size: '14',
                animate: '4'
            },
            {
                path: bubbleYellow,
                size: '15',
                animate: '3'
            },
            {
                path: bubbleBlue,
                size: '16',
                animate: '1'
            },
            {
                path: bubbleBlue,
                size: '16',
                animate: '1'
            },
            {
                path: bubbleRed,
                size: '17',
                animate: '2'
            },
            {
                path: bubbleBlue,
                size: '18',
                animate: '3'
            }
        ]
    }
};

const AnimatedElements = ({ className, theme }) => {
    const classes = React.useMemo(() => {
        let cls = 'wrapperAnimatedElements';
        cls += ` wrapperAnimatedElements_theme_${theme}`;
        cls += className ? ' ' + className : '';

        return cls;
    }, [className, theme]);

    const data = React.useMemo(() => {
        return ANIMATED_ELEMENT_TYPES[theme] ? ANIMATED_ELEMENT_TYPES[theme] : [];
    }, [theme]);

    return (
        <div className={classes}>
            {data.elements.map((element, i) => (
                <div
                    key={element.theme + i.toString()}
                    className={
                        `AnimatedElement
                        AnimatedElement_animate_${element.animate}
                        AnimatedElement_size_${element.size}`
                    }
                ><img className='pulse' src={element.path} alt=""/></div>
            ))}
        </div>
    )
};

export const AnimatedElementsPropTypeThemes = PropTypes.oneOf(['1', '2', '3', '4', '5']);

AnimatedElements.propTypes = {
    className: PropTypes.string,
    theme: AnimatedElementsPropTypeThemes
};

export default AnimatedElements;
