import React from "react";
import '../../styles/layout/footer.scss';
import LanguageSwitcher from "../../components/LanguageSwitcher";
import RoadMap from "./RoadMap";
// import AnimatedElements from "../../components/AnimatedElements";

import whiteLogo from '../../images/logo/white/Logo-H-131-White-90.svg';

const Footer = ({locale, isSimple = false, legalInfo}) => (
    <footer className="Footer">
        {/*<AnimatedElements theme="4" />*/}
        <div className="container Footer-Container">
            <div className='Footer-Content'>
                <div className='Footer-Logo'>
                    <img src={whiteLogo} alt="Лого" />
                </div>
                {!isSimple && <LanguageSwitcher theme="white"/>}
            </div>
            {!isSimple && <RoadMap locale={locale}/> }
            <div className='Footer-LegalInfo'>
                {legalInfo}
            </div>
        </div>
    </footer>
);

export default Footer;
