import React from 'react';

import '../styles/buttons/headerOnlineBank.scss';
import iconOnlinebank from '../images/icons/buttons/onlineBankButton.svg';

const HeaderOnlineBank = ({ locale, goto = '', type = 'text' }) => {
  const listLanguage = React.useMemo(() => {
    return {
      textButton: {
        en: 'Log In',
        ru: 'Интернет-банк',
      }[locale],
    };
  }, [locale]);

  return (
    <div>
      <a
        className="onlineBankButton"
        href={goto}
        rel="noreferrer"
        target="_blank"
        type={type}
      >
        <span className="Button-Text">{listLanguage.textButton}</span>
      </a>

      <a
        className="onlineBankButton_mobile"
        href={goto}
        rel="noreferrer"
        target="_blank"
        type={type}
      >
        <img src={iconOnlinebank} className="MenuButton-Img"/>
      </a>
    </div>
  );
};

export default HeaderOnlineBank;
