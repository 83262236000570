import React from "react";
import PropTypes from 'prop-types';

import '../styles/components/circleWithImage.scss';

import iconCheckFirst from '../images/icons/list/first.svg';
import iconCheckSecond from '../images/icons/list/second.svg';

const CircleWithImage = ({ children, className,  size = 'medium', color = 'first', check = false }) => {
    const classes = React.useMemo(() => {
        let cls = 'CircleWithImage';
        cls += className ? ` ${className}` : '';
        cls += size ? ` CircleWithImage_size_${size}` : '';
        cls += color ? ` CircleWithImage_color_${color}` : '';
        cls += check ? ` CircleWithImage_icon_check` : '';

        return cls;
    }, [className, size, color, check]);

    return (
        <div className={classes}>
            <span className="CircleWithImage-Content">
                {children}
                {check && color === 'first' && <img src={iconCheckFirst} alt="" />}
                {check && color === 'second' && <img src={iconCheckSecond} alt="" />}
            </span>
        </div>
    );
};

CircleWithImage.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['medium', 'small']),
    color: PropTypes.oneOf(['first', 'second']),
    check: PropTypes.bool
};

export default CircleWithImage;
