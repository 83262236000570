import React from 'react';
import { Link } from "gatsby"
import CircleWithImage from "../components/CircleWithImage";

const ProductMainMenu = ({ menu = [] }) => {
  var colorName = "first";
  var iconImage;
  var counterItem = 0;
  return (
      <div className="MenuLinksWithIconList">
          {menu.map((menuRow, i) => (
              <div className="MenuLinksWithIconList-Row" key={i}>
                  {menuRow.map((menuItem, j) => (
                      colorName = counterItem%2 === 0 ? 'first' : 'second',
                      counterItem = counterItem + 1,
                      iconImage = require("../images/icons/styled/" + colorName + "/" + menuItem.icon ),
                      <Link className="MenuLinkWithIcon" to={menuItem.url} activeClassName="active" key={j}>
                          <CircleWithImage size="small" color={colorName}>
                              <img src={iconImage} alt={menuItem.title} />
                          </CircleWithImage>
                          <span className="MenuLinkWithIcon-Text">{menuItem.title}</span>
                      </Link>
                  ))}
              </div>
          ))}
      </div>
  )
};

export default ProductMainMenu;
