const links = {
    ru: {
        api: 'https://developer.131.ru/docs/ru/intro'
    },
    en: {
        api: 'https://developer.131.ru/docs/en/intro'
    }
};

export default links;
