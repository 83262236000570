/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header/Header"
import "../styles/index.scss"
import IconsSprite from "../components/icons/IconsSprite";
import Footer from "./footer/Footer";
import Cookie from "../components/Cookie";
import { Helmet } from "react-helmet";
import queryString from 'query-string'
import { useCookies } from 'react-cookie'

const Layout = ({children, locale, isSimple, footerLegalInfo }) => {

    const [cookies, setCookie] = useCookies([
        'b131_utm_source',
        'b131_utm_medium',
        'b131_utm_campaign',
        'b131_utm_term',
        'b131_utm_content'
    ]);

    let expires = new Date()
    expires.setTime(expires.getTime() + 2592000000);

    const isBrowser = typeof window !== "undefined"

    if (isBrowser) {
        const {
            utm_source,
            utm_medium,
            utm_campaign,
            utm_term,
            utm_content
        } = queryString.parse(window.location.search);

        if ( utm_source ) { setCookie('b131_utm_source', utm_source, { path: '/',  expires}); };
        if ( utm_medium ) { setCookie('b131_utm_medium', utm_medium, { path: '/',  expires}); };
        if ( utm_campaign ) { setCookie('b131_utm_campaign', utm_campaign, { path: '/',  expires}); };
        if ( utm_term ) { setCookie('b131_utm_term', utm_term, { path: '/',  expires}); };
        if ( utm_content ) { setCookie('b131_utm_content', utm_content, { path: '/',  expires}); };
    };

    return (
        <React.Fragment>
            <Helmet>
                <meta name="yandex-verification" content="7b62d6152cf39ea6" />
            </Helmet>
            <IconsSprite />
            <Header locale={locale} isSimple={isSimple} />
            <div id="main">
                <div className="Main">
                    {children}
                </div>
                <Footer locale={locale} isSimple={isSimple} legalInfo={footerLegalInfo} />
            </div>
            <Cookie locale={locale}/>
        </React.Fragment>
    )
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout
