import React from 'react';
import PropTypes from 'prop-types';

import '../styles/components/menuButton.scss';

import iconBurgerMenu from "../images/icons/burger-menu/Burger-Menu.svg";
import iconClose from "../images/icons/burger-menu/Close.svg";

const MenuButton = ({ open = 'open', onClick }) => {
    const classes = React.useMemo(() => {
        let cls = 'MenuButton';
        cls += ` MenuButton_status_${open ? 'open' : 'close'}`;

        return cls;
    }, [open]);

    return (
        <button className={classes} type="button" onClick={onClick}>
            <img src={!open ? iconBurgerMenu : iconClose} alt="" className="MenuButton-Img" />
        </button>
    );
};

MenuButton.propTypes = {
    open: PropTypes.bool,
    onClick: PropTypes.func
};

export default MenuButton;
