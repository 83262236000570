export const gridBreakpoints = {
    xs: 0,
    md: 768,
    lg: 1024,
    xl: 1441,
    fhd: 1921
};

export const gridUnit = 8;

export const gridGutterDefault = gridUnit * 2;

export const gridGutters = {
    xs: gridGutterDefault,
    md: gridUnit * 4,
    lg: gridUnit * 4,
    xl: gridUnit * 4,
    fhd: gridUnit * 4
};
