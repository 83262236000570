import React from 'react';

import { Link } from 'gatsby';
import ProductMainMenu from '../../components/ProductMainMenu';

import '../../styles/layout/components/navbar.scss';

import useLanguage from '../../hooks/useLanguage';

import enMessages from '../../i18n/navbar/en.json';
import ruMessages from '../../i18n/navbar/ru.json';

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const Navbar = ({ locale }) => {
  const [currentLanguage] = useLanguage();

  return (
    <nav className="Menu">
      <ul className="Menu-List">
        <li className="Menu-ListCell Menu-ListCell_hasSubmenu">
          <span className="Menu-Link">
            {messages[locale].businessSolutions}
          </span>
          <div className="Submenu Submenu_withIcons">
            <div className="Submenu-Inner">
              <div className="clearNegativeMargin">
                <ProductMainMenu menu={messages[locale].mainMenuItems} />
              </div>
            </div>
          </div>
        </li>
        <li className="Menu-ListCell">
          <Link
            to={`${currentLanguage === 'en' ? '/en' : ''}/pricing`}
            className="Menu-Link"
            activeClassName="active"
            partiallyActive={true}
          >
            {messages[locale].pricing}
          </Link>
        </li>
        <li className="Menu-ListCell Menu-ListCell_hasSubmenu Menu-ListCell_hasRelativeSubmenu">
          <span className="Menu-Link">{messages[locale].company}</span>
          <div className="Submenu">
            <div className="Submenu-Inner">
              <div className="menuLinksList">
                <Link
                  className="menuLink"
                  to={`${currentLanguage === 'en' ? '/en' : ''}/about`}
                  activeClassName="active"
                >
                  {messages[locale].aboutBank}
                </Link>
                <Link
                  className="menuLink"
                  to={`${currentLanguage === 'en' ? '/en' : ''}/careers`}
                  activeClassName="active"
                >
                  {messages[locale].vacancy}
                </Link>
                <Link
                  className="menuLink"
                  to={`${currentLanguage === 'en' ? '/en' : ''}/blog`}
                  activeClassName="active"
                >
                  {messages[locale].blog}
                </Link>
              </div>
            </div>
          </div>
        </li>
        {locale === 'ru' && (
          <li className="Menu-ListCell">
            <Link
              to={`${currentLanguage === 'en' ? '/en' : ''}/contacts`}
              className="Menu-Link"
              activeClassName="active"
              partiallyActive={true}
            >
              {messages[locale].feedback}
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
