import React from 'react';
import { Link } from 'gatsby';

import '../styles/components/insuranceLogo.scss';

import bigLogo from '../images/logo/insurance/120x120.svg';
import smallLogo from '../images/logo/insurance/80x80.svg';
import useLanguage from '../hooks/useLanguage';

const InsuranceLogo = () => {
    const [currentLanguage] = useLanguage();
    const prefixUrl = (currentLanguage || 'ru') === 'en' ? 'en' : '';

    return (
        <Link to={`/${prefixUrl}legal/infoRates`} className="InsuranceLogo">
            <img alt="logo" src={smallLogo} className="InsuranceLogo-Small" />
            <img alt="logo" src={bigLogo} className="InsuranceLogo-Big" />
        </Link>
    )
};

export default InsuranceLogo;
