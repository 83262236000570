import React from 'react'
import PropTypes from 'prop-types';

import '../styles/components/rectangle.scss';

const Rectangle = ({ title = '', description = '', styleText = '', config = {} }) => {
  const classesText = React.useMemo(() => {
    let cls = 'Rectangle-text';

    if (styleText) {
      cls += ` Rectangle-text-${styleText}`;
    }

    return cls;
  }, [styleText]);

  const classesRectangle = React.useMemo(() => {
    let cls = 'Rectangle';

    if ((config || {}).borderBottom) {
      cls += ` Rectangle-Border-${config.borderBottom}`;
    }

    return cls;
  }, [config]);

  return (
    <div className={classesRectangle}>
      <div className="Rectangle-Inner">
        <div className="Rectangle-Cell">
          {title ? (
            <div className="Rectangle-CellTitle" dangerouslySetInnerHTML={{__html: title}}/>
          ) : null}

          <div className={classesText}>
            {description ? (
              <div dangerouslySetInnerHTML={{__html: description}}/>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export const RectanglePropTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};

Rectangle.propTypes = RectanglePropTypes;

export default Rectangle;
