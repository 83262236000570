import React from 'react';
import { Link } from "gatsby"
import CircleWithImage from "../components/CircleWithImage";

const ProductMainMenu = ({ menu = [] }) => {
   var colorName = "first";
   var iconImage;
   var counterItem = 0;
   return (
       <div className="listMobileMenuLinkWithIcon">
           {menu.map((menuRow, i) => (
               menuRow.map((menuItem, j) => (
                   colorName = (i+(j-1))%2 === 0 ? 'second' : 'first',
                   counterItem = counterItem + 1,
                   iconImage = require("../images/icons/styled/" + colorName + "/" + menuItem.icon ),
                   <Link className="MobileMenuLinkWithIcon" to={menuItem.url} activeClassName="active" key={`${menuItem.url}_${j}`}>
                       <CircleWithImage size="small" color={colorName}>
                           <img src={iconImage} alt={menuItem.title} />
                       </CircleWithImage>
                       <span className="MobileMenuLinkWithIcon-Text">{menuItem.title}</span>
                   </Link>
               ))
           ))}
       </div>
   )
};

export default ProductMainMenu;
